/* .alert {
  position: absolute;
  bottom: 0;
}

.alert-enter {
  opacity: 1 !important;
  transform: translateY(-100%);
  transition: all 1s ease-in;
}
.alert-enter-active {
  opacity: 1;

  transform: translateY(-100%);
  transition: all 1s ease-in;
}
.alert-exit {
  opacity: 1;
  transform: translateY(-100%);
  transition: all 1s ease-in;
}
.alert-exit-active {
  opacity: 1;
  transform: translateY(-100%);
}

.alert-enter-done {
  opacity: 1 !important;

  transform: translateY(-100%);
}

.alert-exit-done {
  opacity: 1 !important;
  transform: translateY(-100%);
} */

.alert {
  opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  transition: all 1s ease-in;
}

.alert-enter {
  /* opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  transition: opacity 1s ease-in, transform 1s ease-in; */
}
.alert-enter-active {
  opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  transition: opacity 1s ease-in, transform 1s ease-in;
}
.alert-exit {
  opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  transition: opacity 1s ease-in, transform 1s ease-in;
}
.alert-exit-active {
  /* opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  transition: opacity 1s ease-in, transform 1s ease-in; */
}

.alert-enter-done {
  /* opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  transition: opacity 1s ease-in, transform 1s ease-in; */
}

.alert-exit-done {
  opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  transition: opacity 1s ease-in, transform 1s ease-in;
  /* opacity: 0 !important;
  transform: translateY(100%) scale(0) !important;
  transition: none !important; */
  /* display: none; */
}

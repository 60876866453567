.new-bot-msg {
  text-align: left;
  padding: 0.875rem;
  display: inline-block;
  min-width: 25%;
  max-width: 100%;
  margin-inline: auto;
  color: #faf8ff;
  align-self: flex-start;
  background: hsla(0, 0%, 0%, 0.4);
  border: 0.5px solid hsla(0, 0%, 100%, 0.21);
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  border-radius: 12px;
  font-size: 0.875rem;
  letter-spacing: 0.05rem;
  line-height: 1.1rem;
}

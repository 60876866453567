.suggestions {
  width: 100%;
  // position: absolute;
  top: 50%;
  min-height: 8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
  left: 50%;
  transform: scale(0);

  transition: transform 0.5s ease-in;
  padding: 0 1.25rem;
  .suggestion-sub-div {
    color: #fff;
    font-size: 0.875rem;
    padding: 0.875rem 0.5rem;
    background-color: hsl(0deg 0% 0% / 40%);
    border: 0.5px solid hsla(0, 0%, 100%, 0.21);
    backdrop-filter: blur(27px);
    border-radius: 8px;
    letter-spacing: 0.02rem;
    line-height: 1.1rem;

    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    &:hover {
      color: #fff;
      background: #000;
    }
  }
}

.suggestions-animation {
  transform: scale(1);
}

.homepage {
  width: 100%;
  position: relative;
  min-height: 100dvh;
  background: #191c2b;
  background-image: url("./../../assets/img/Homepage/bg-1.svg"),
    url("./../../assets/img/Homepage/bg-2.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
  padding-top: 8.125rem;

  .homepage__back-btn {
    position: absolute;
    top: 3rem;
    left: 1.5rem;
  }
  .homepage__header-div {
    color: #ffffff;
    text-align: center;

    .homepage__header {
      font-size: 1.375rem;
      line-height: 120%;
      font-weight: 500;
    }
    .homepage__sub-header {
      color: #ff4ff8;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
  .homepage__bot-grid {
    text-align: center;
    padding: 3.375rem 1.25rem;
    display: grid;
    gap: 1.25rem;
    grid-template-columns: 1fr 1fr;
    .homepage__bot {
      &:hover {
        border: 1px solid white;
        border-radius: 4px;
        padding-bottom: 1rem;
      }
      .homepage__bot-img {
        margin-bottom: 0.5rem;
      }
      .homepage__bot-name {
        font-weight: 500;
        font-size: 1.125rem;
        color: #faf8ff;
        margin-bottom: 0.375rem;
        line-height: 100%;
      }
      .homepage__bot-description {
        font-weight: 400;
        font-size: 0.875rem;
        color: #b5b7c1;
      }
    }
  }
}

.poc-text {
  color: #fff;
  font-size: 1rem;
  line-height: 150%;
  padding: 1rem;
}

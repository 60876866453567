.back-nav {
  z-index: 10;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  .back-nav__back-button,
  .back-nav__refresh-button {
    cursor: pointer;
  }
}

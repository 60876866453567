/* .react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
} */

.autosuggest {
  position: relative;
}

.autosuggest__input {
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.21);
  border-radius: 20px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100%;
}
.autosuggest__input::placeholder {
  color: #d5d5d5;
  font-size: 0.875rem;
  font-weight: 500;
  /* font-style: italic; */
}
.autosuggest__input:focus {
  outline: none;
}
.autosuggest__suggestions-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.autosuggest__suggestions-container ul li {
  font-size: 0.875rem;
}
.autosuggest__suggestions-container:hover {
  background-color: rgb(232, 232, 232);
}
.autosuggest__suggestions-container {
  position: absolute;
  width: 68%;
  z-index: 10;
  /* background-color: #fff; */
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  margin-left: 0.5em;
}

.autosuggest__suggestion {
  padding: 10px;
  cursor: pointer;
}

.autosuggest__suggestion:hover {
  background-color: #f2f2f2;
}

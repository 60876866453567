.search-bar {
  width: 100%;
  padding: 0.5rem 1.25rem;
  display: flex;
  gap: 0.5rem;
  position: fixed;
  bottom: -64px;
  background: transparent;
  transition: bottom 0.5s linear;
  .search-bar__input {
    background: black;
    width: 100%;
    font-size: 1rem;
    opacity: 80%;
    line-height: 140%;
    border-radius: 55px;
    padding: 0.875rem;
    color: #fff;
    border: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #5e6683;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 140%;
    }
  }
}

.search-input-div-animation {
  bottom: 0;
}

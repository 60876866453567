.default-class {
  opacity: 0;
  transform: translateY(100%) scale(0);
  transition: opacity 0.4s ease-in, transform 0.4s ease-in;
  margin-top: auto;
  width: 100%;
  z-index: 100;
}
.msg-class {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.showCard {
  opacity: 1 !important;
  transform: translateY(0) scale(1) !important;
  transition: none !important;
}
.messaage-div-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50vh;
  background: linear-gradient(
    180deg,
    #1b1d32 0%,
    #302e6c 47.4%,
    rgba(89, 69, 202, 0) 100%
  );
  z-index: 2;
}
.message-div__component {
  // margin-top: auto;
  width: 100%;
}
.bot-svg-img {
  width: 8rem;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  z-index: 100;
}
.outer-message-div {
  background: url("./../../assets/img/bg-msg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // padding: 27.125rem 0 9rem;
  height: 100vh;
  padding: 8rem 1rem 4rem;

  width: 100%;
  overflow: auto;
}
.message-recomendation__text {
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  height: 4rem;
  text-align: center;
  padding: 0 1.25rem;
  z-index: 1;
  // height: 3.375rem;
  width: 100%;
  margin-top: auto;
  margin-bottom: 1.5rem;
  // position: absolute;
  // top: 65%;
  // opacity: 0;
  // transform: scale(0.5);
  // transition: opacity 0.5s ease-out, top 0.5s ease-out,
  //   transform 0.5s ease-out;
}

.message-recomendation__text-animation {
  top: 44%;
  opacity: 1;
  transform: scale(1);
}
.botclass {
  transform: translateY(0) scale(1);
  transition: transform 0.5s linear, opacity 0.5s ease-out;
  // height: 100%;
  width: 100%;
  position: absolute;
  // bottom: 36%;
  opacity: 1;

  z-index: 3;
}
.botclass-animation {
  opacity: 0;
  transform: translateY(0) scale(0);
}
.message-page {
  position: relative;
  // width: 100%;
  // height: 100vh;
  // padding: 5.125rem 0 6.25rem;
  // overflow: auto;
  background-color: #171a25;
  // overflow: hidden;
  .message-div {
    display: flex;
    position: relative;
    margin: 0 1.25rem;
    flex-direction: column;
    gap: 1.5rem;
    height: 19vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .message-recomendation {
    // background: url("./../../assets/img/chat-recomendation-bg.svg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // padding: 27.125rem 0 0rem;
    // height: 100vh;
    width: 100%;
    // overflow: auto;
    // background: url("./../../assets/img/chat-recomendation-bg.svg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // position: absolute;
    // bottom: 0;
    // z-index: 10;
    // min-height: 100vh;
    // padding-top: 76px;
    // padding-bottom: 2.5rem;
    // height: 45rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: flex-end;
    // padding: 20.125rem 1.25rem 7.25rem;
    // padding: 0rem 1.25rem 7rem;

    // padding: 0 1.25rem 7.25rem;

    z-index: 1;
    // position: relative;
    gap: 1.375rem;
    margin-bottom: 1rem;
    transform: translateY(100%);
    // overflow: auto;
    transition: transform 1s ease-in;
    .message-recomendation__bg {
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 1;
    }
    .message-recomendation__arrow-div {
      z-index: 1;
      padding: 0.5rem 0;
      width: 100%;
      text-align: center;
      .message-recomendation__arrow {
        width: 1rem;
        transition: transform 0.8s ease-in;
      }
    }
    .message-recomendation__bot {
      transform: scale(0.1) translateY(0px);
      // animation: bounce 0.6s infinite alternate;
      position: absolute;
      top: 25%;
      z-index: 1;
      transition: transform 1s ease-in, top 0.5s ease-in;
    }
  }

  .bottom-reveal-animation {
    opacity: 1;
    transform: translateY(0);
  }
  .overlay {
    position: absolute;
    z-index: 10;
    top: 3.813rem;
    width: 100%;
    height: 6.25rem;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0.01%,
      rgba(0, 0, 0, 0.4) 0.02%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

@keyframes botBounce {
  from {
    transform: scale(1) translateY(10px);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

.animation-class {
  animation: botBounce 0.6s infinite alternate;
}

// .lottie-file {
//   position: absolute;
//   top: -14%;
// }

.bot-into-text {
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  margin-bottom: 1.25rem;
  transform: translateY(4rem);
  opacity: 0;
  transition: transform 0.5s ease-in, opacity 0.5s ease-in;
  span {
    font-weight: 500;
    font-size: 1.25rem;
  }
}

.bot-intro-text-anim {
  transform: translateY(0);
  opacity: 1;
}

// .search-input-div {
//   transform: translateY(100%);
//   transition: transform 0.5s linear;
// }
.suggestion-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
.suddestion-main-div {
  padding: 1rem;
  width: 80%;
  height: 80vh;
  border-radius: 16px;
  background-color: #2b2e3c;
  z-index: 4;
}

.modal-cancel-btn {
  z-index: 4;
}
